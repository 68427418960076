import React from 'react';
import styled from '@emotion/styled'
import { Inner, WrapConstrained } from 'styles/structure';
import SliceZone from 'components/SliceZone';
import Authors from 'components/_page/resource/Authors';
import PostDek from 'components/_page/post/Dek';

const ContentContainer = styled.div`
  & + * {
    margin-top: 8rem;
  }
  
  &:last-of-type {
    margin-bottom: 8rem;
  }
`;

const PostContent = ({ deck, body, authors }) => {
  return (
    <ContentContainer>
      <Inner>
        {deck && <PostDek deck={deck} />}

        {body && (
          <SliceZone
            body={body}
          />
        )}

        {(authors && authors.length > 0 && authors[0].author) && (
          <WrapConstrained>
            <Authors authors={authors}/>
          </WrapConstrained>
        )}
      </Inner>
    </ContentContainer>
  );
}

export default PostContent;
