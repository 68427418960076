/* eslint-disable limited-exports-page-templates */

import React from 'react';
import { graphql } from 'gatsby';
import Seo from 'components/Seo';
import Layout from 'components/Layout';
import PostHero from 'components/PostHero';
import PostContent from 'components/_page/post/Content';
import FeaturedPosts from 'components/FeaturedPosts';
import { withPreview } from 'gatsby-source-prismic'

export const PostPage = ({ data }) => {
  const post = data.prismicPost.data;

  if(!post) {
    return null;
  }

  return (
    <Layout>
      <Seo
        title={post.social_title || post.title.text || 'Blog Post'}
        description={post.social_description}
        image={post.social_image || post.preview_image || post.feature_image}
        unlisted={post.unlisted}
      />


      <PostHero
        background={post.feature_image}
        category={post.category && post.category.document && post.category.document.data.title}
        title={post.title.text}
        publishDate={post.publish_date || post.first_publication_date}
        authors={post.authors}
      />

      <PostContent
        deck={post.deck}
        body={post.body}
        authors={post.authors}
      />

      {(post.related_posts && post.related_posts.length > 0 && post.related_posts[0].post && post.related_posts[0].post.document) && (
        <FeaturedPosts
          heading='More from the Blog'
          posts={post.related_posts.slice(0,4)}
          buttonText='View all posts'
          destination='/blog'
          variant='post'
        />
      )}
    </Layout>
  );
}

export const query = graphql`
  query getPost($uid: String!) {
    prismicPost(uid: {eq: $uid}) {
      uid
      first_publication_date
      data {
        title {
          text
        }
        unlisted
        deck
        publish_date
        category {
          document {
            ... on PrismicCategory {
              uid
              data {
                title
              }
            }
          }
        }
        authors {
          author {
            document {
              ... on PrismicPerson {
                uid
                data {
                  name
                  short_bio
                  image {
                    url
                    alt
                  }
                  hide_bio
                }
              }
            }
          }
        }
        feature_image {
          url
          alt
        }
        preview_image {
          url
          alt
        }
        related_posts {
          post {
            document {
              ... on PrismicPost {
                uid
                data {
                  title {
                    raw
                    text
                  }
                  deck
                  category {
                    document {
                      ... on PrismicCategory {
                        data {
                          title
                        }
                      } 
                    }
                  }
                  feature_image {
                    url
                    alt
                  }
                  preview_image {
                    url
                    alt
                  }
                }
              }
            }
          }
        }
        body {
          ... on PrismicPostBodyText {
            slice_type
            primary {
              content {
                raw
              }
            }
          }
          ... on PrismicPostBodyPullquote {
            slice_type
            primary {
              text
            }
          }
          ... on PrismicPostBodyBlockImage {
            slice_type
            primary {
              image {
                url
                alt
              }
              caption
              credit
              url
            }
          }
          ... on PrismicPostBodyCustomHtml {
            slice_type
            primary {
              content {
                text
              }
            }
          }
          ... on PrismicPostBodyFeaturedContent {
            slice_type
            primary {
              heading
            }
            items {
              content_item {
                document {
                  ... on PrismicPost {
                    uid
                    type
                    data {
                      title {
                        text
                      }
                      category {
                        document {
                          ... on PrismicCategory {
                            data {
                              title
                            }
                          }
                        }
                      }
                      feature_image {
                        url
                        alt
                      }
                      preview_image {
                        url
                        alt
                      }
                      social_image {
                        url
                      }
                    }
                  }
                  ... on PrismicDonate {
                    url
                    type
                    data {
                      hero_heading {
                        text
                      }
                      social_image {
                        url
                      }
                    }
                  }
                  ... on PrismicFaqs {
                    url
                    type
                    data {
                      title {
                        text
                      }
                      social_image {
                        url
                      }
                    }
                  }
                  ... on PrismicContactPage {
                    url
                    type
                    data {
                      social_image {
                        url
                      }
                    }
                  }
                  ... on PrismicCollections {
                    url
                    type
                    data {
                      hero_heading {
                        text
                      }
                      social_image {
                        url
                      }
                    }
                  }
                  ... on PrismicPrograms {
                    url
                    type
                    data {
                      hero_heading {
                        text
                      }
                      social_image {
                        url
                      }
                    }
                  }
                  ... on PrismicBlog {
                    url
                    type
                    data {
                      hero_heading {
                        text
                      }
                      social_image {
                        url
                      }
                    }
                  }
                  ... on PrismicSimplePage {
                    uid
                    type
                    data {
                      title {
                        text
                      }
                      social_image {
                        url
                      }
                      hero_background {
                        url
                        alt
                      }
                    }
                  }
                  ... on PrismicResource {
                    uid
                    type
                    data {
                      title {
                        text
                      }
                      preview_image {
                        url
                        alt
                      }
                      feature_image {
                        url
                        alt
                      }
                      topic {
                        document {
                          ... on PrismicTopic {
                            data {
                              title
                            }
                          }
                        }
                      }
                      resource_type {
                        document {
                          ... on PrismicType {
                            data {
                              title
                            }
                          }
                        }
                      }
                    } 
                  }
                  ... on PrismicProgram {
                    uid
                    type
                    data {
                      title {
                        text
                      }
                      social_image {
                        url
                      }
                      hero_background {
                        url
                        alt
                      }
                      tout_image {
                        url
                        alt
                      }
                    }
                  }
                  ... on PrismicProgramArm {
                    uid
                    type
                    data {
                      title {
                        text
                      }
                      social_image {
                        url
                      }
                      hero_background {
                        url
                        alt
                      }
                      tout_image {
                        url
                        alt
                      }
                    }
                  }
                  ... on PrismicTeam {
                    url
                    type
                    data {
                      title {
                        text
                      }
                      social_image {
                        url
                      }
                    }
                  }
                  ... on PrismicHomepage {
                    url
                    type
                    data {
                      hero_heading {
                        text
                      }
                      social_image {
                        url
                      }
                    }
                  }
                  ... on PrismicPerson {
                    uid
                    type
                    data {
                      name
                      job_title
                      organization
                      image {
                        url
                        alt
                      }
                      social_image {
                        url
                      }
                    }
                  }
                  ... on PrismicResources {
                    url
                    type
                    data {
                      hero_heading {
                        text
                      }
                      social_image {
                        url
                      }
                    }
                  }
                  ... on PrismicAboutUs {
                    url
                    type
                    data {
                      hero_heading {
                        text
                      }
                      social_image {
                        url
                      }
                    }
                  }
                  ... on PrismicExternalLink {
                    type
                    data {
                      link_title: title
                      subtitle
                      preview_image {
                        url
                        alt
                      }
                      destination
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPostBodyColumns {
            slice_type
            items {
              heading {
                raw
              }
              content {
                raw
              }
            }
          }
          ... on PrismicPostBodyTrapdoors {
            slice_type
            primary {
              trapdoor_color
            }
            items {
              heading {
                raw
              }
              content {
                raw
                text
              }
              destination
              button_text
              alignment
              hover_image {
                url
                alt
              }
            }
          }
          ... on PrismicPostBodyFaq {
            slice_type
            items {
              question
              answer {
                raw
                text
              }
            }
          }
          ... on PrismicPostBodyEvent {
            slice_type
            primary {
              event {
                document {
                  ... on PrismicEvent {
                    id
                    data {
                      date
                      end_date
                      feature_image {
                        alt
                        url
                      }
                      introduction {
                        raw
                      }
                      format
                      location
                      location_detailed {
                        text
                        raw
                      }
                      participation
                      recording
                      registration_url {
                        url
                      }
                      social_description
                      social_image {
                        alt
                        url
                      }
                      social_title
                      time
                      time_detailed {
                        raw
                        text
                      }
                      title {
                        raw
                        text
                      }
                      disclaimer_text
                      tout_image {
                        alt
                        url
                      }
                      tout_text {
                        raw
                        text
                      }
                    }
                    uid
                    url
                  }
                }
              }
            }
          }
        }
        social_title
        social_description
        social_image {
          url
        }
      }
    }
  }
`;

export default withPreview(PostPage);