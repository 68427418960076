import React from 'react';
import styled from '@emotion/styled'
import { Inner } from 'styles/structure';
import colors from 'styles/colors';
import dimensions from 'styles/dimensions';
import RichText from 'components/RichText';
import PostsGrid from 'components/_grid/Posts';
import PostCard from 'components/_card/Post';
import ArrowLink from 'components/_ui/ArrowLink';

const PostsContainer = styled.div`
  position: relative;
  width: 100%;
  
  & + * {
    margin-top: 5.6rem;
  }
  
  &:last-of-type {
    margin-bottom: 5.6rem;
  } 
  
  header {
    border-top: 0.2rem solid ${colors.yellow500};
    padding-top: 4rem;
    
    > div {
      > * + * {
        margin-top: 1.6rem;
      }
      
      & + * {
        margin-top: 3.2rem;
      }
    }
    
    & + * {
      margin-top: 4rem;
    }
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {  
    header {
      display: flex;
      align-items: center;
      padding-top: 2.4rem;
      
      > div {
        flex: 1 1 100%;
        
        > * {
          max-width: 62.8rem;
        }
      }
      
      a {
        flex-shrink: 0;
      }
    }
  }
`;

const FeaturedPosts = ({ heading, buttonText, destination, posts, variant }) => {
  let postsHeading;

  if (heading && heading[0].text) {
    postsHeading = <RichText render={heading}/>;
  } else if (heading) {
    postsHeading = <h3>{heading}</h3>;
  }

  return (
    <PostsContainer className={`${variant ? `FeaturedPosts--${variant}` : ''}`}>
      <Inner>
        {(heading || (buttonText && destination)) && (
          <header>
            <div>
              {postsHeading}
            </div>

            {(buttonText && destination) && (
              <ArrowLink text={buttonText} destination={destination} />
            )}
          </header>
        )}

        {(posts && posts.length > 0) && (
          <PostsGrid dense={posts.length > 3}>
            {posts.map((feature, i) => {
              if (feature.node) {
                return (
                  <li key={`feature-post-${i}`}>
                    <PostCard
                      uid={feature.node.uid}
                      image={feature.node.data.preview_image || feature.node.data.feature_image}
                      title={feature.node.data.title[0].text}
                      category={feature.node.data.category && feature.node.data.category.title}
                    />
                  </li>
                );
              } else if (feature.post) {
                const { document } = feature.post;

                return (
                  <li key={`feature-post-${i}`}>
                    <PostCard
                      uid={document.uid}
                      image={document.data.preview_image || document.data.feature_image}
                      title={document.data.title.text}
                      category={(document.data.category && document.data.category.document) && document.data.category.document.data.title}
                    />
                  </li>
                )
              }

              return null;
            })}
          </PostsGrid>
        )}
      </Inner>
    </PostsContainer>
  );
}

export default FeaturedPosts;
