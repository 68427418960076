import React from 'react';
import styled from '@emotion/styled'
import { WrapConstrained } from 'styles/structure';
import { Deck } from 'styles/typography';

const DekContainer = styled.div`
  & + * {
    margin-top: 8rem;
  }
  
  &:last-of-type {
    margin-bottom: 8rem;
  }
`;

const PostDek = ({ deck }) => {

  return (
    <DekContainer>
      <WrapConstrained>
        {deck && <Deck>{deck}</Deck>}
      </WrapConstrained>
    </DekContainer>
  );
}

export default PostDek;
